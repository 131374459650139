<header class="main-header">

  <a href="#" class="logo" title="hazie">
    <img class="company-logo" src="../../../../assets/logos/Hazie-logo-white.png" alt="">
  </a>

  <input type="checkbox" class="nav-toggle" id="nav-toggle">

  <nav>
    <ul class="nav-links">
      <li class="nav-link">
        <a routerLink="/applications">
          <img src="../../../../assets/icons/applications.svg" alt="" class="nav-icon"><br>
          <small>Applications</small>
        </a>
      </li>
      <li *ngIf="authentication"  class="nav-link cursor">
        <a (click)="goToApplication(true);">
          <img src="../../../../assets/icons/folder.svg" alt="" class="nav-icon"><br>
          <small>My Applications</small>
        </a>
      </li>
      <li *ngIf="authentication" (click)="goToProfile()" class="nav-link">
        <a routerLink="/profile">
          <img src="../../../../assets/icons/user-2.svg" alt="" class="nav-icon"><br>
          <small>Profile</small>
        </a>
      </li>
      <li *ngIf="!authentication" (click)="signIn()" class="nav-link">
        <a >
          <img src="../../../../assets/icons/login.svg" alt="" class="nav-icon"><br>
          <small>Sign In</small>
        </a>
      </li>

      <li *ngIf="authentication" (click)="logout()" class="nav-link cursor">
        <a >
          <img src="../../../../assets/icons/logout.svg" alt="" class="nav-icon"><br>
          <small>Sign Out</small>
        </a>
      </li>
    </ul>
  </nav>

  <label for="nav-toggle" class="nav-toggle-label">
    <span></span>
  </label>

</header>

<jw-modal id="myapplications" >
  <div class="header-row">
    <div class="header">My Applications</div>
    <button class="close" (click)="closeMyApplications()">
      <img src="../../../../assets/icons/close.svg" alt="">
    </button>
  </div>

  <div class="modal-body"  >
    <div class="container">
      <div class="card" *ngFor="let application of applicationStagesProgrgess" (click)="toApplication(application)">
        <img src="{{application.bannerImage}}" alt="application-banner">
        <div class="info">
          <div class="title">{{application.title}}</div>
          <div class="svg">
            <app-stages-svg [candidateStages]="application.stages"></app-stages-svg>
          </div>
          <div class="card-footer">
            <div class="industry">Industry: {{getIndustry(application.industry)}}</div>
            <div class="company-logo">
              <img src="{{application.logoImage}}" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="showMessage === true" class="error-message">
      <div>You have not yet Applied for any Opportunities</div>
    </div>
  </div>
</jw-modal>
