import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {CreateAssignmentModel} from '../../Core/Models/CreateAssignment.Model';
import {Observable} from 'rxjs/internal/Observable';
import {AssignmentResultsModel} from '../../Core/Models/AssignmentResults.Model';

@Injectable()
export class OdysseyService {

  baseUrl = environment.baseUrl + environment.apiVersion;
  OdysseyEndpoints = `${this.baseUrl}/QuestionsAndAnswers/Odyssey/`;
  constructor(private httpClient: HttpClient) {
  }

  CreateAssignment(mode: CreateAssignmentModel ): Observable<any> {
    return this.httpClient.post<any>(`${this.OdysseyEndpoints}CreateAssignment`, mode);
  }

  GetResults(IdNumber: string, OrderId: string, stageId: string): Observable<AssignmentResultsModel> {
    return this.httpClient.post<AssignmentResultsModel>(`${this.OdysseyEndpoints}GetResults`, {OrderId, IdNumber, stageId});
  }
}
