import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {FilterCriteria} from '../../Core/Models/FilterCriteria';
import {Guid} from 'guid-typescript';
import {ReferralsModel} from '../../Core/Models/Referrals.Model';
import {Injectable} from '@angular/core';

@Injectable()
export class ReferralsService {

  baseUrl = environment.baseUrl + environment.apiVersion;
  ReferralsEndpoints = `${this.baseUrl}/Referrals/`;
  constructor(private httpClient: HttpClient) {
  }

  GetById(id: string): Observable<ReferralsModel> {
    return this.httpClient.get<ReferralsModel>(`${this.ReferralsEndpoints}GetById?id=${id}`);
  }

  GetByFilterCriteria(filterCriteria: FilterCriteria): Observable<ReferralsModel[]> {
    return this.httpClient.post<ReferralsModel[]>(`${this.ReferralsEndpoints}all`, filterCriteria);
  }

  Upsert(model: ReferralsModel): Observable<string> {
    return this.httpClient.post<string>(`${this.ReferralsEndpoints}Upsert`, model);
  }
}
