import {Guid} from 'guid-typescript';

export class LocationApplicationModel {
  applicationId: Guid;
  lineone: string;
  linetwo: string;
  linethree: string;
  linefour: string;
  code: number;
}
