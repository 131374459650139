import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {QuestionModel} from '../../../../../Core/Models/Question.Model';
import {Subject} from 'rxjs';
import {QuestionsAndAnswersService} from '../../../../../Services/QuestionsAndAnswersService/QuestionsAndAnswers.Service';

@Component({
  selector: 'app-input-statement',
  templateUrl: './input-statement.component.html',
  styleUrls: ['./input-statement.component.scss']
})
export class InputStatementComponent implements OnInit, OnDestroy {

  @Input()
  renderedQuestion: QuestionModel;
  @Input()
  candidateId: string;
  @Input()
  onNextButtonClicked: Subject<boolean>;
  @Output()
  validate: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private questionsService: QuestionsAndAnswersService) { }

  ngOnInit(): void {
    this.onNextButtonClicked.subscribe(value => {
      this.validate.emit(true);
    });
  }

  onNextButton(): void{
    this.validate.emit(true);
  }

  ngOnDestroy(): void {
  }

}
