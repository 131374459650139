<app-navbar></app-navbar>
<div class="main-container">
  <div class="background-canvas">
    <div class="card">
      <div class="title">Profile</div>
      <div *ngIf="candidate" class="main-section">
        <div class="left-inner">
          <img src="{{getCorrectProfileIcon(candidate)}}" alt="profile-icon">
          <div class="name">{{candidate.firstName}} {{candidate.lastName}}</div>
          <button type="button" class="button" (click)="goToEditProfile(candidate.candidateId)">EDIT</button>
        </div>
        <div class="right-inner">
          Name: {{candidate.firstName}} <br>
          ID Number: {{candidate.idNumber}} <br>
          Home Language: {{sanitizeHomeLanguage(candidate.homeLanguage)}} <br>
          Current City: {{candidate.currentCity}} <br>
          Population Group: {{sanitizePopulationGroup(candidate.populationGroup)}} <br>
          Gender:  {{sanitizeGender(candidate.gender)}} <br>
          Contact Number: {{sanitizeContactNumber(candidate.cellphoneNumber)}} <br>
          Email Address: {{candidate.email}}<br>
        </div>
      </div>
    </div>
  </div>
</div>

