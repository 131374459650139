import {BaseModel} from './Base.Model';

export class DocumentModel extends BaseModel{
  fileName: string;
  candidateId: string;
  fileType: string;
  fileSize: string;
  lastModified: number;
  url: string;
  question: string;
  purpose: string;
}
