import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {AuthenticationService} from './authentication.service';
import {Observable, from} from 'rxjs';
import {Injectable, Injector} from '@angular/core';
import {environment} from '../../../environments/environment';


@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
  private authorizeUrl = `${environment.identityUrl}/connect/token`;

  constructor(private injector: Injector) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // We don't add tokens to the identity server requests
    if (request.url === this.authorizeUrl) {
      return next.handle(request);
    }
    const authenticationService = this.injector.get(AuthenticationService);

    if (!authenticationService.checkIfAuthenticated() && authenticationService.hasRefreshToken() && request.url !== this.authorizeUrl) {
      from(authenticationService.refreshToken()).subscribe(response => {
        return next.handle(request);
      }, error => {
        return next.handle(request);
      });
    } else {
      return next.handle(request);
    }
  }
}
