import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Guid} from 'guid-typescript';
import {Observable} from 'rxjs';
import {StageModel} from '../../../Core/Models/Stage.Model';
import {FilterCriteria} from '../../../Core/Models/FilterCriteria';
import {StageCandidateApplicationProgress} from '../../../Core/Enums/Progress.Enums';
import {CandidateStageProgressModel} from '../../../Core/Models/CandidateStageProgress.Model';

@Injectable({
  providedIn: 'root'
})
export class StagescandidatejobService {

  baseUrl = environment.baseUrl + environment.apiVersion;
  stageCandidateApplicationEndpoint = `${this.baseUrl}/StageCandidateApplication/`;

  constructor(private https: HttpClient) { }

  GetStageCandidateJob(candidateId: Guid, stageId: Guid): Observable<CandidateStageProgressModel> {
    return this.https.get<CandidateStageProgressModel>(`${this.stageCandidateApplicationEndpoint}GetStageCandidateJob?candidateId=${candidateId}&stageId=${stageId}`);
  }

  UpsertStageCandidateJob(model: CandidateStageProgressModel): Observable<string> {
    return this.https.post<string>(`${this.stageCandidateApplicationEndpoint}UpsertStageCandidateJob`, model);
  }

  GetApplicationCandidates(applicationId: string): Observable<string[]> {
    return this.https.get<string[]>(`${this.stageCandidateApplicationEndpoint}GetApplicationCandidates?applicationid=${applicationId}`);
  }

  GetCandidateApplications(candidateId: Guid): Observable<string[]> {
    return this.https.get<string[]>(`${this.stageCandidateApplicationEndpoint}GetCandidateApplications?candidateid=${candidateId}`);
  }

  GetCandidateApplicationStages(candidateId: string, applicationId: string): Observable<CandidateStageProgressModel[]> {
    return this.https.get<CandidateStageProgressModel[]>(`${this.stageCandidateApplicationEndpoint}GetCandidateApplicationStages?candidateId=${candidateId}&applicationId=${applicationId}`);
  }
}
