<form [formGroup]="inputForm">
  <ng-multiselect-dropdown
    id="multi-select-input"
    [placeholder]="'-select-option-'"
    [settings]="dropdownSettings"
    [data]="dropdownList"
    [(ngModel)]="selectedItems"
    formControlName="formValue"
    (onSelect)="onItemSelect($event)"
    (onDeSelect)="onDeSelect($event)"
    (onSelectAll)="onSelectAll($event)"
    (onDeSelectAll)="onDeSelectAll($event)">
    <ng-template
      #optionsTemplate
      let-item
      let-option="option"
      let-id="id"
      let-isSelected="isSelected"
    >
      <div style="padding: 1px 10px;">
              <span
                style="display: block; margin-left: 30px;"
                [ngStyle]="{ 'background': isSelected ? 'lightgrey':''}"
              >
                {{option}}
              </span>
      </div>
    </ng-template>
  </ng-multiselect-dropdown>
  <div class="error-messages" *ngIf="formValue.touched">
    <div *ngIf="formValue.value === '-Select-Option-' && formValue.dirty">* Please choose correct value</div>
    <div *ngIf="!formValue.value && formValue.invalid">* value can't be blank</div>
  </div>
</form>

