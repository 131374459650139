import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {FilterCriteria} from '../../Core/Models/FilterCriteria';
import {Guid} from 'guid-typescript';
import {CompanyModel} from '../../Core/Models/Company.Model';
import {Injectable} from '@angular/core';

@Injectable()
export class CompaniesService {

  baseUrl = environment.baseUrl + environment.apiVersion;
  CompanyEndpoints = `${this.baseUrl}/Companies/`;
  constructor(private httpClient: HttpClient) {
  }

  GetById(id: string): Observable<CompanyModel> {
    return this.httpClient.get<CompanyModel>(`${this.CompanyEndpoints}GetById?id=${id}`);
  }

  GetByFilterCriteria(filterCriteria: FilterCriteria): Observable<CompanyModel[]> {
    return this.httpClient.post<CompanyModel[]>(`${this.CompanyEndpoints}all`, filterCriteria);
  }

  Upsert(model: CompanyModel): Observable<string> {
    return this.httpClient.post<string>(`${this.CompanyEndpoints}Upsert`, model);
  }

  UpdateIsActive(id: string, isActive: boolean): Observable<string> {
    return this.httpClient.get<string>(`${this.CompanyEndpoints}isActive?id=${id}&isActive=${isActive}`);
  }

  softDelete(id: Guid): Observable<string> {
    return this.httpClient.get<string>(`${this.CompanyEndpoints}isActive?id=${id}`);
  }
}
