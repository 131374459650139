import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ApplicationModel} from '../../Core/Models/Application.Model';
import {FilterCriteria} from '../../Core/Models/FilterCriteria';
import {Guid} from 'guid-typescript';
import {Injectable} from '@angular/core';

@Injectable()
export class ApplicationsService {

  baseUrl = environment.baseUrl + environment.apiVersion;
  ApplicationsEndpoints = `${this.baseUrl}/Applications/`;
  localUrl = `https://localhost:5001/api/v1/Applications/`;
  constructor(private httpClient: HttpClient) {
  }

  GetById(id: Guid): Observable<ApplicationModel> {
    return this.httpClient.get<ApplicationModel>(`${this.ApplicationsEndpoints}GetById?id=${id}`);
  }

  GetByFilterCriteria(filterCriteria: FilterCriteria): Observable<ApplicationModel[]> {
    return this.httpClient.get<ApplicationModel[]>(`${this.ApplicationsEndpoints}all`);
  }

  Upsert(model: ApplicationModel): Observable<string> {
    return this.httpClient.post<string>(`${this.ApplicationsEndpoints}Upsert`, model);
  }

  UpdateIsActive(id: string, isActive: boolean): Observable<string> {
    return this.httpClient.get<string>(`${this.ApplicationsEndpoints}isActive?id=${id}&isActive=${isActive}`);
  }


  softDelete(id: Guid): Observable<string> {
    return this.httpClient.get<string>(`${this.ApplicationsEndpoints}isActive?id=${id}`);
  }

}
