import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import {QuestionModel} from '../../../../../Core/Models/Question.Model';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {QuestionsAndAnswersService} from '../../../../../Services/QuestionsAndAnswersService/QuestionsAndAnswers.Service';

@Component({
  selector: 'app-input-select',
  templateUrl: './input-select.component.html',
  styleUrls: ['./input-select.component.scss']
})
export class InputSelectComponent implements OnInit {

  @Input() question: QuestionModel;
  @Input() candidateId: string;
  @Output() answer: EventEmitter<string> = new EventEmitter<string>();
  dropdownList = [];
  value: any;
  inputForm: FormGroup;

  constructor(private formBuilder: FormBuilder,
              private questionsService: QuestionsAndAnswersService) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initiateForm();
    this.prefillDropDownList();
    this.prefillSelectedItem();
  }

  initiateForm(): void {
    this.inputForm = this.formBuilder.group({
      formValue: ['', [Validators.required]]
    });
  }
  /* -Mark form as touched- */
  markFormFieldsAsTouched(): void {
    this.formValue.markAsTouched();
  }

  /* Form Getters */
  get formValue(): AbstractControl {
    return this.inputForm.get('formValue');
  }

  prefillDropDownList(): void {
    this.dropdownList = this.question.options.split(',');
  }

  prefillSelectedItem(): void {
    this.getCandidateQuestionAnswer();
  }

  getCandidateQuestionAnswer(): void {
    this.questionsService.GetCandidateAnswer(this.candidateId, this.question.id).subscribe(answer => {

      if (answer) {
        this.inputForm = this.formBuilder.group({
          formValue: [answer.answer, [Validators.required]]
        });

        this.answer.emit(answer.answer);
      }
    });
  }

  onChange(value: any): void {
    this.markFormFieldsAsTouched();

    if (this.formValue.value === '-Select-Option-') {
      this.value = undefined;
    } else {
      this.value = this.formValue.value;
    }

    this.answer.emit(this.value);
  }

}
