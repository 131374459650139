import {Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {QuestionModel} from '../../../../../Core/Models/Question.Model';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {QuestionsAndAnswersService} from '../../../../../Services/QuestionsAndAnswersService/QuestionsAndAnswers.Service';
import {DocumentsService} from '../../../../../Services/DocumentsService/documents.service';
import {FilterCriteria} from '../../../../../Core/Models/FilterCriteria';
import {DocumentModel} from '../../../../../Core/Models/Document.Model';

@Component({
  selector: 'app-input-upload',
  templateUrl: './input-upload.component.html',
  styleUrls: ['./input-upload.component.scss']
})
export class InputUploadComponent implements OnInit {

  @Input() question: QuestionModel;
  @Input() candidateId: string;
  @Output() answer: EventEmitter<string> = new EventEmitter<string>();
  @Output() childQuestion: EventEmitter<string> = new EventEmitter<string>();
  @Output() purpose: EventEmitter<string> = new EventEmitter<string>();
  @Output() documentUploaded: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() file: EventEmitter<File> = new EventEmitter<File>();
  @Output() documentModel: EventEmitter<DocumentModel> = new EventEmitter<DocumentModel>();
  value = '';
  url = '';
  fileForBinding: File;
  inputForm: FormGroup;
  @ViewChild('fileInput') fileInput: ElementRef;

  constructor(private formBuilder: FormBuilder,
              private questionsService: QuestionsAndAnswersService,
              private  uploadService: DocumentsService) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initiateForm();
    this.fetchCandidateDocument();
  }

  initiateForm(): void {
    this.inputForm = this.formBuilder.group({
      formValue: ['', [Validators.required, Validators.minLength(1)]]
    });
    this.fileForBinding = null;
    console.log('changes happened!');
  }

  /* -Mark form as touched- */
  markFormFieldsAsTouched(): void {
    this.formValue.markAsTouched();
  }

  /* Form Getters */
  get formValue(): AbstractControl {
    return this.inputForm.get('formValue');
  }

  fetchCandidateDocument(): void {
    const filterCriteria = new FilterCriteria();
    filterCriteria.SourceKeys = [];

    this.questionsService.GetCandidateAnswer(this.candidateId, this.question.id).subscribe(answer => {
      const file = new FileReader();
      this.clearInputField();

      if (answer) {

      }

      this.uploadService.GetDocumentInfo(this.candidateId, this.getDocumentPurpose(this.question.question)).subscribe(fileInfo => {
        // this.clearInputField();
        this.inputForm.get('formValue').setValue(fileInfo);
        this.documentModel.emit(fileInfo);
        if (fileInfo) {
          this.documentUploaded.emit(true);
        } else {
          this.documentUploaded.emit(false);
        }
      });
    });


  }

  getDocumentPurpose(questionText: string): string {
    let purposeFound = 'Not Found!';
    const purposeList = [
      'certified ID',
      'uncertified ID',
      'Id',
      'Grade 10 Results',
      'Grade 11 Results',
      'Grade 12 Results',
      'Application Form',
      'Mid-Year Results',
      'Mid Year Results',
      'Final Year Results',
      'First Year Results',
      'Second Year Results',
      'Third Year Results',
      'Fourth Year Results',
      'NBT',
      'Banking Details',
      'Certified Qualification',
      'Qualification',
      'CV',
      'Tax',
      'Affidavit',
      'residential',
      'Proof of payment'
    ];

    const lowerCaseQuestion = questionText.trim().toLowerCase();

    purposeList.forEach( purpose => {
      if (lowerCaseQuestion.includes(purpose.toLowerCase())) {
        purposeFound = purpose.toLowerCase();
        console.log(purpose);
        return ;
      }
    });

    return purposeFound;
  }

  onChange(event: any): void {
    this.markFormFieldsAsTouched();
    this.value = this.formValue.value;

    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.inputForm.get('formValue').setValue(file);
      this.answer.emit(file.name);
      this.file.emit(file);
      this.purpose.emit(this.getDocumentPurpose(this.question.question));
      this.childQuestion.emit(this.question.question);

      const reader = new FileReader();
      reader.onload = () => {
        this.url = reader.result as string;
      };
      reader.readAsDataURL(file);
    }
  }

  clearInputField(): void {
    this.fileInput.nativeElement.value = null;
  }

}
