<form [formGroup]="inputForm">
  <select
    name="select-input"
    id="select-input"
    formControlName="formValue"
    (change)="onChange($event)">
<!--    [ngModelOptions]="{standalone: true}">-->
    <option>-Select-Option-</option>
    <option value="{{item}}" *ngFor="let item of dropdownList">{{item}}</option>
  </select>
  <div class="error-messages" *ngIf="formValue.touched">
    <div *ngIf="formValue.value === '-Select-Option-' && formValue.dirty">* Please choose correct value</div>
    <div *ngIf="!formValue.value && formValue.invalid">* value can't be blank</div>
  </div>
</form>

