<div class="container background">
  <div class="left-box">
    <div class="company-logo">
      <a routerLink="/login" title="oldmutual">
        <img  class="logo" src="../../../../assets/logos/Hazie-logo-white.png" alt="Card image cap">
      </a>
    </div>
  </div>

  <div class="right-box">
    <div class="card">
      <div class="card-header">
        <div class="company-logo">
          <a href="https://hazie.co.za" target="_blank">
            <img  class="logo" src="../../../../assets/logos/Hazie-logo.png" alt="Card image cap">
          </a>
        </div>

        <div class="header-text">
          <h1>SIGN UP</h1>
          <small>TO APPLY</small>
        </div>
      </div>

      <div class="card-body">
        <div class="form-container">
          <form  [formGroup]="registerForm" (ngSubmit)="registerCandidate()">
            <input
              placeholder="FIRST NAME"
              formControlName="firstName"
              type="text"
              id="firstname"/>

            <input
              placeholder="LAST NAME"
              formControlName="lastName"
              type="text"
              id="lastname"/>

            <input
              placeholder="IDENTITY NUMBER"
              formControlName="idNumber"
              type="text"
              id="idnumber"/>

            <input
              placeholder="EMAIL ADDRESS"
              formControlName="email"
              type="text"
              id="username"/>

            <input
              placeholder="PASSWORD"
              formControlName="password"
              type="password"
              id="password"/>

            <input type="submit" class="form-control" value="SIGN UP">
          </form>
        </div>
      </div>

      <div class="card-footer">
        <div class="errors">
          <div class="error-messages" *ngIf="firstName.touched">
            <div *ngIf="firstName.invalid && firstName.dirty">* First Name needs to be at least 3 characters</div>
            <div *ngIf="!firstName.value">* First Name can't be blank</div>
          </div>

          <div class="error-messages" *ngIf="lastName.touched">
            <div *ngIf="lastName.invalid && lastName.dirty">* Last Name needs to be at least 3 characters</div>
            <div *ngIf="!lastName.value">Last Name can't be blank</div>
          </div>

          <div class="error-messages" *ngIf="idNumber.touched">
            <div *ngIf="idNumber.invalid && idNumber.dirty">* ID Number pattern incorrect </div>
            <div *ngIf="!idNumber.value">* ID Number can't be blank</div>
          </div>

          <div class="error-messages" *ngIf="email.touched">
            <div *ngIf="email.invalid && email.dirty">Email needs to be something like me@example.com </div>
            <div *ngIf="!email.value">* Email can't be blank</div>
          </div>

          <div class="error-messages" *ngIf="password.touched">
            <div *ngIf="password.invalid && password.dirty">Password needs to be at least 6 characters </div>
            <div *ngIf="!password.value">* Password can't be blank</div>
          </div>

          <div class="error-messages">
            <div *ngIf="submitted">{{errorMessages}}</div>
          </div>
        </div>

        <div class="forgot-password">
          <div class="sign-up-login">
            Have an account? <a routerLink="/login" class="color-alert">Sign In</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
