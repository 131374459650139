import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {QuestionsAndAnswersService} from '../../../../../Services/QuestionsAndAnswersService/QuestionsAndAnswers.Service';
import {QuestionModel} from '../../../../../Core/Models/Question.Model';

@Component({
  selector: 'app-input-free-text',
  templateUrl: './input-free-text.component.html',
  styleUrls: ['./input-free-text.component.scss']
})
export class InputFreeTextComponent implements OnInit {

  @Input() question: QuestionModel;
  @Input() candidateId: string;
  @Output() answer: EventEmitter<string> = new EventEmitter<string>();
  value = '';
  inputForm: FormGroup;

  constructor(private formBuilder: FormBuilder,
              private questionsService: QuestionsAndAnswersService) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initiateForm();
    this.getCandidateQuestionAnswer();
  }

  initiateForm(): void {
    this.inputForm = this.formBuilder.group({
      formValue: ['', [Validators.required, Validators.minLength(1)]]
    });
  }

  /* -Mark form as touched- */
  markFormFieldsAsTouched(): void {
    this.formValue.markAsTouched();
  }

  /* Form Getters */
  get formValue(): AbstractControl {
    return this.inputForm.get('formValue');
  }

  getCandidateQuestionAnswer(): void {
    this.questionsService.GetCandidateAnswer(this.candidateId, this.question.id).subscribe(answer => {
      console.log(answer);
      if (answer) {
        this.inputForm = this.formBuilder.group({
          formValue: [answer.answer, [Validators.required]]
        });

        this.answer.emit(answer.answer);
      }
    });
  }

  onChange(value: any): void {
    this.markFormFieldsAsTouched();
    // this.value = value.target.value;
    this.value = this.formValue.value;
    console.log(this.value);
    this.answer.emit(this.value);
  }

}
