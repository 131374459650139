import {BaseModel} from './Base.Model';
import {UploadTypeModel} from './UploadType.Model';
import {Guid} from 'guid-typescript';

export class DocumentUploadModel extends BaseModel{

  uploadType: UploadTypeModel ;
  uploadEntityName: string;
  sourceId: string;
  fileURL: string;
  fileName: string;
  fileExtesion: string;
  fileSize: number;

}
