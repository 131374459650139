import {Component, Input, OnInit} from '@angular/core';
import {CandidateStageProgressModel} from '../../../../../Core/Models/CandidateStageProgress.Model';
import {Industry} from '../../../../../Core/Models/Industry';

@Component({
  selector: 'app-stages-svg',
  templateUrl: './stages-svg.component.html',
  styleUrls: ['./stages-svg.component.scss']
})
export class StagesSvgComponent implements OnInit {

 @Input() candidateStages: CandidateStageProgressModel[];

  constructor() {
  }

  ngOnInit(): void {
  }
}
