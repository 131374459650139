export class UserRegistrationModel {
  ClientId: string;
  Id: string;
  Email: string;
  FirstName: string;
  LastName: string;
  Username: string;
  UseAD: boolean;
  Password: string;
  ConfirmPassword: boolean;
  IsDashboard: boolean;
  ReturnUrl: string;
}
