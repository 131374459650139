import { Component, OnInit } from '@angular/core';
import {ResetPasswordModel} from '../../../Core/Models/ResetPassword.Model';
import {ActivatedRoute, Router} from '@angular/router';
import {Title, Meta} from '@angular/platform-browser';
import {AuthenticationService} from '../../../Services/AuthenticationService/authentication.service';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';


@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  resetPassordModel: ResetPasswordModel;
  confirmPassword: string;
  isMatched = true;

  ResetPasswordForm: FormGroup;
  submitted = false;
  errorMessages: string[];



  constructor(private router: Router,
              private route: ActivatedRoute,
              private meta: Meta,
              private formBuilder: FormBuilder,
              private authenticationService: AuthenticationService,
              private titleService: Title
              // private titleService: Title
              ) { }

  ngOnInit(): void {

    this.initiateForm();

    this.resetPassordModel = new ResetPasswordModel();
    this.route.params.subscribe(params => {

      this.resetPassordModel.code = params.code;
      this.resetPassordModel.email = params.email;
      console.log(this.resetPassordModel);
    });

    if (!this.resetPassordModel.code || !this.resetPassordModel.email){
      console.log('verification id is invalid/missing');
      this.router.navigate(['./error']);
    }else{

    }
  }

  initiateForm(): void {
    this.ResetPasswordForm = this.formBuilder.group({
      // oldPassword: ['', [Validators.required, Validators.minLength(6)]],
      newPassword: ['', [Validators.required, Validators.minLength(6)]]
    });
  }

  changePassword(): void {

    console.log('something');
    this.markFormFieldsAsTouched();

    if (this.ResetPasswordForm.valid) {
      this.errorMessages = [];
      this.resetPassordModel.password = this.newPassword.value;

      this.authenticationService.changePassword(this.resetPassordModel)
        .subscribe(data => {
          console.log('Password Change Successful');
          localStorage.setItem('verify', 'verify-reset');
          this.router.navigate(['./verifyUser']);
          // this.router.navigate(['./login', {message: 'Password Change Successful'}]);
        }, data => {
          this.submitted = true;
          this.errorMessages.push('Failed to reset password.');
          this.router.navigate(['./error']);
          console.log(data);
        });
    }
  }

  markFormFieldsAsTouched(): void {
    // this.oldPassword.markAsTouched();
    this.newPassword.markAsTouched();
  }

  /* -ResetPasswordForm Getters- */
  // get oldPassword(): AbstractControl {
  //   return this.ResetPasswordForm.get('oldPassword');
  // }

  get newPassword(): AbstractControl {
    return this.ResetPasswordForm.get('newPassword');
  }

}
