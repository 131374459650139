import { Component, OnInit } from '@angular/core';
import {FilterCriteria} from '../../../Core/Models/FilterCriteria';
import {AuthenticationService} from '../../../Services/AuthenticationService/authentication.service';
import {ActivatedRoute, Router} from '@angular/router';
import {LoginModel} from '../../../Core/Models/Login.Model';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  model: LoginModel;
  returnUrl: string;
  isBusy: boolean;
  errorMessages: string[];
  message: string;
  title = 'HAZIE - Sign in';
  submitted = false;
  loginForm: FormGroup;

  constructor(private authenticationService: AuthenticationService,
              private route: ActivatedRoute,
              private router: Router,
              private formBuilder: FormBuilder,
              private titleService: Title
              ) { }

  ngOnInit(): void {

    this.initiateForm();

    this.route.params.subscribe(params => {
      this.message = params.message;
    });

    this.model = new LoginModel();
    /* get return url from route parameters or default to 'applications' */
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || 'applications';
    console.log(this.returnUrl);
    this.titleService.setTitle(this.title);

  }

  initiateForm(): void {
    this.loginForm = this.formBuilder.group({
      email: ['',
        [Validators.required,
          Validators.pattern('^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$')]],
      password: ['', [Validators.required, Validators.minLength(6)]]
    });
  }

  login(): void {
    /* -Mark form as touched- */
    this.email.markAsTouched();
    this.password.markAsTouched();

    if (this.loginForm.valid) {
      this.isBusy = true;
      this.errorMessages = [];
      if (this.model.email === undefined || this.model.password === undefined){
        this.submitted = true;
        this.errorMessages.push('Login Failed. Please double check your credentials');
        return;
      }


      this.authenticationService.login(this.model.email, this.model.password).then((response) => {
        if (response.isValid) {
          this.router.navigate([this.returnUrl]);
        } else {
          this.submitted = true;
          this.errorMessages = response.errorMessages;
        }
      }).catch((response) => {
        this.submitted = true;
        this.errorMessages.push('Login Failed. Please double check your credentials');
      });
    }
  }

  /* -LoginForm Getters- */
  get email(): AbstractControl {
    return this.loginForm.get('email');
  }

  get password(): AbstractControl {
    return this.loginForm.get('password');
  }
}
