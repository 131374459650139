import { Component, OnInit } from '@angular/core';
import {FilterCriteria} from '../../../Core/Models/FilterCriteria';
import {AuthenticationService} from '../../../Services/AuthenticationService/authentication.service';
import {CandidateModel} from '../../../Core/Models/CandidateModelModell.Model';
import {CandidatesService} from '../../../Services/CandidatesService/candidates.service';
import {CandidatesprofileService} from '../../../Services/ProfilesService/CandidateService/candidatesprofile.service';
import {CandidateProfileModel} from '../../../Core/Models/CandidateProfile.Model';
import {Router} from '@angular/router';
import {Subject} from 'rxjs';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-on-boarding-questions',
  templateUrl: './on-boarding-questions.component.html',
  styleUrls: ['./on-boarding-questions.component.scss']
})
export class OnBoardingQuestionsComponent implements OnInit {
  title = 'On-Boarding';
  public filterCriteria: FilterCriteria;
  candidateProfile: CandidateProfileModel;
  onNextButtonClicked: Subject<boolean> = new Subject();
  indexOfQuestion = 0;
  question;
  isLoaded: boolean;
  showBackButton: boolean;
  onboardQuestions: any[];
  progressStep = 100 / 11;
  progressStyling = 'width:5%';

  constructor(private authenticationService: AuthenticationService,
              private candidatesprofileService: CandidatesprofileService,
              private titleService: Title,
              private router: Router) {
    this.onboardQuestions = new Array<any>();
    this.onboardQuestions.push('Please enter you cell phone number');
    this.onboardQuestions.push('Please enter another contact number');
    this.onboardQuestions.push('Please enter your current city');
    this.onboardQuestions.push('Please enter your date of birth');
    this.onboardQuestions.push('Do you have a disability');
    this.onboardQuestions.push('Please select a gender');
    this.onboardQuestions.push('Please enter your home language');

    this.onboardQuestions.push('Please enter population group, e.g. African, Cocasian, Asian, Indin');

    this.question = this.onboardQuestions[this.indexOfQuestion];
    const userDetails = this.authenticationService.getUserCredential();
    if (userDetails.username) {
      this.filterCriteria = new FilterCriteria();
      this.filterCriteria.EmailKey = userDetails.username;
      this.getCandidateProfile();
    }


  }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
  }

  async getCandidateProfile( ){

    const observableProfileCandidates =  this.candidatesprofileService.GetByFilterCriteria(this.filterCriteria);
    const resultProfileCandidate = await observableProfileCandidates.toPromise();
    this.candidateProfile = resultProfileCandidate.find(x => x.userName === this.filterCriteria.EmailKey);
    console.log(this.candidateProfile)
    if (this.candidateProfile.isNewAccount === true){
      this.isLoaded = true;

    }

    else{
      this.router.navigate(['./applications']);
    }


  }

  nextQuestion(){

    this.showBackButton = true;

    if (this.indexOfQuestion < 7){
      this.indexOfQuestion++;
      this.question = this.onboardQuestions[this.indexOfQuestion];
      this.onNextButtonClicked.next(true);
      this.updateBar();


    }else if (this.indexOfQuestion === 7 ) {
      this.indexOfQuestion++;
      this.onNextButtonClicked.next(true);
      this.question = 'Thank you';
      this.updateBar();
    }else{
      this.onNextButtonClicked.next(true);


    }


  }

  updateBar(): void {
    this.progressStep = ((this.indexOfQuestion) / (this.onboardQuestions.length)) * 100;
    this.progressStyling = `width: ${this.progressStep }%`;
  }

  onBackButton(): void {
    this.indexOfQuestion--;
    this.question = this.onboardQuestions[this.indexOfQuestion];
    if ( this.indexOfQuestion === 0) {
      this.showBackButton = false;
    }
    this.updateBar();
  }



}
