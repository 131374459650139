<label>
  <input *ngIf="isProfile === undefined || isProfile === false"  [(ngModel)]="this.answerCandidate.answer" type="text" name="answer" class="step-input" >
  <input *ngIf="isProfile === true && indexOfLoadingQuestion === 0 || indexOfLoadingQuestion === 1 || indexOfLoadingQuestion ===7 " type="number" name="answer"  [(ngModel)]="this.profileAnswer" class="step-input" >
  <input *ngIf="isProfile === true && indexOfLoadingQuestion ===2  || indexOfLoadingQuestion ===4 ||indexOfLoadingQuestion === 6
         || indexOfLoadingQuestion ===8"
         type="text" name="answer"  [(ngModel)]="this.profileAnswer" class="step-input" >
  <select *ngIf="isProfile === true && indexOfLoadingQuestion === 5" [ngModelOptions]="{standalone: true}" [(ngModel)]="this.profileAnswer"  required class="form-control step-input" >
    <option [ngValue]="0">-select Option-</option>
    <option [ngValue]="0">Male</option>
    <option [ngValue]="1">Female</option>
  </select>
  <input *ngIf="isProfile === true && indexOfLoadingQuestion === 3" type="date" name="answer"  [(ngModel)]="this.profileAnswer" class="step-input" >

</label>
