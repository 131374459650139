import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {FilterCriteria} from '../../Core/Models/FilterCriteria';
import {Guid} from 'guid-typescript';
import {CandidatesPerStageModel} from '../../Core/Models/CandidatesPerStage.Model';
import {ReferralsModel} from '../../Core/Models/Referrals.Model';
import {ReferralStatsModel} from '../../Core/Models/ReferralStats.Model';
import {Injectable} from '@angular/core';

@Injectable()
export class ReferralStatsService {

  baseUrl = environment.baseUrl + environment.apiVersion;
  ReferralStatsEndpoints = `${this.baseUrl}/ReferralStats/`;
  constructor(private httpClient: HttpClient) {
  }

  GetById(id: string): Observable<ReferralStatsModel> {
    return this.httpClient.get<ReferralStatsModel>(`${this.ReferralStatsEndpoints}GetById?id=${id}`);
  }

  GetByFilterCriteria(filterCriteria: FilterCriteria): Observable<ReferralStatsModel[]> {
    return this.httpClient.post<ReferralStatsModel[]>(`${this.ReferralStatsEndpoints}all`, filterCriteria);
  }

  AllCount(filterCriteria: FilterCriteria): Observable<number> {
    return this.httpClient.post<number>(`${this.ReferralStatsEndpoints}AllCount`, filterCriteria);
  }

  Upsert(model: ReferralStatsModel): Observable<string> {
    return this.httpClient.post<string>(`${this.ReferralStatsEndpoints}Upsert`, model);
  }
}
