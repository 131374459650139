import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {FilterCriteria} from '../../Core/Models/FilterCriteria';
import {Guid} from 'guid-typescript';
import {MailModel} from '../../Core/Models/Mail.Model';
import {Injectable} from '@angular/core';

@Injectable()
export class NotificationsService {

  baseUrl = environment.baseUrl + environment.apiVersion;
  NotificationEndpoints = `${this.baseUrl}/Notifications`;
  constructor(private httpClient: HttpClient) {
  }

  GetById(id: string): Observable<MailModel> {
    return this.httpClient.get<MailModel>(`${this.NotificationEndpoints}GetById?id=${id}`);
  }

  GetByFilterCriteria(filterCriteria: FilterCriteria): Observable<MailModel[]> {
    return this.httpClient.post<MailModel[]>(`${this.NotificationEndpoints}all`, filterCriteria);
  }

  SendMail(model: MailModel): Observable<string> {
    return this.httpClient.post<string>(`${this.NotificationEndpoints}SendMail`, model);
  }
}
