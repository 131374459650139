import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {QuestionModel} from '../../../Core/Models/Question.Model';
import {StageModel} from '../../../Core/Models/Stage.Model';
import {CandidateModel} from '../../../Core/Models/CandidateModelModell.Model';
import {ApplicationModel} from '../../../Core/Models/Application.Model';
import {environment} from '../../../../environments/environment';
import {isArray} from 'util';
import {ActivatedRoute, Router} from '@angular/router';
import {DomSanitizer} from '@angular/platform-browser';
import {FormBuilder} from '@angular/forms';
import {FilterCriteria} from '../../../Core/Models/FilterCriteria';
import {AnswerModel} from '../../../Core/Models/Answer.Model';
import {CandidateStageProgressModel} from '../../../Core/Models/CandidateStageProgress.Model';
import {QuestionsAndAnswersService} from '../../../Services/QuestionsAndAnswersService/QuestionsAndAnswers.Service';
import {StagesService} from '../../../Services/StagesService/Stages/stages.service';
import {NotificationsService} from '../../../Services/NotificationsService/Notifications.Service';
import {ApplicationsService} from '../../../Services/ApplicationsService/Applications.Service';
import {DocumentsService} from '../../../Services/DocumentsService/documents.service';
import {CandidatesService} from '../../../Services/CandidatesService/candidates.service';
import {StagescandidatejobService} from '../../../Services/StagesService/StagesCandidateJob/stagescandidatejob.service';
import {StageCandidateApplicationProgress} from '../../../Core/Enums/Progress.Enums';
import {Subject} from 'rxjs';
import {AuthenticationService} from '../../../Services/AuthenticationService/authentication.service';
import {Guid} from 'guid-typescript';

@Component({
  selector: 'app-load-questions',
  templateUrl: './load-questions.component.html',
  styleUrls: ['./load-questions.component.scss']
})
export class LoadQuestionsComponent implements OnInit {

  onNextButtonClicked: Subject<boolean> = new Subject();

  Candidate: CandidateModel;
  Stages: StageModel[];
  list: QuestionModel[] = [];
  renderedQuestion: QuestionModel = null;
  currentStage: StageModel;
  applicationModel: ApplicationModel = new ApplicationModel();
  form: any;
  progressStep = 100 / 11;
  progressStyling = 'width:5%';
  showBackButton: boolean;
  isPromoted: boolean;
  isValide = true;
  isComms = false;
  isClicked = false;
  comsMessage = 'Testing';
  currentprogress: CandidateStageProgressModel;

  constructor(private questionsService: QuestionsAndAnswersService, private stageService: StagesService,
              private stagescandidatejobService: StagescandidatejobService, private authenticationService: AuthenticationService,
              private mailService: NotificationsService, private route: ActivatedRoute, private applicationsService: ApplicationsService,
              private  uploadService: DocumentsService, private router: Router, private sr: DomSanitizer,
              private candidateService: CandidatesService, private cdr: ChangeDetectorRef, private formBuilder: FormBuilder
  ) {
    this.applicationModel.id = this.route.snapshot.params.id;


    this.Candidate = new CandidateModel();
    this.getApplication(this.applicationModel.id);

    this.getCandidate();
  }

 ngOnInit(): void {



  }

 async getApplication(id) {
    const observableApplication = this.applicationsService.GetById(Guid.parse(id))
    const resultApplication = await observableApplication.toPromise();
    this.applicationModel = resultApplication;
  }

  async getCandidate() {
    const filterCriteria = new FilterCriteria();
    const user = this.authenticationService.getUserCredential();
    filterCriteria.EmailKey = user.username;

    const observableCandidates =  this.candidateService.GetByFilterCriteria(filterCriteria);
    const resultCandidate = await observableCandidates.toPromise().then( res => {
      this.Candidate = res[0];
      this.getAllApplicationStages(this.applicationModel.id);

    }, msg => {

    });

   /* this.candidateService.GetByFilterCriteria(filterCriteria)
      .subscribe(candidate => {
        this.Candidate = candidate.pop();
        console.log(this.Candidate);

      },error => {
      });*/
  }

  async getAllApplicationStages(id): Promise<any> {
    const filterCriteria = new FilterCriteria();
    filterCriteria.ApplicationsKeys = [id];
    await this.stageService.GetByFilterCriteria(filterCriteria)
      .subscribe(x => {
        this.Stages = x;
        this.currentStage = this.Stages[0];
        console.log('current stage', this.currentStage);
        this.getStageCandidateJob();
      }, error => {
      });
  }

  getQuestionsForStage(stageId): void {
    const filterCriteria = new FilterCriteria();
    filterCriteria.StageKeys = [stageId];
    this.questionsService.GetByFilterCriteria(filterCriteria)
      .subscribe(questions => {
        questions.forEach(question => {
          if (question.options && !isArray(question.options)) {
            question.options = question.options.split(',');
           // question.answer.answer = question.options[0];
          }
        });

        const filtereQuestion = questions.filter( x => x.questionType !== 5);
        console.log(filtereQuestion);
        this.list = filtereQuestion;
        if(this.list.length > 0){
          this.renderedQuestion = this.list[0];
          this.getAnswerForQuestion();
          this.resetBar();
        }
        else{
          this.currentStage = this.Stages[this.Stages.indexOf(this.currentStage) + 1];
          this.getStageCandidateJob();
        }

      }, error =>  {
      });
  }

  getStageCandidateJob(): void {
    this.stagescandidatejobService.GetStageCandidateJob(Guid.parse(this.Candidate.id), Guid.parse(this.currentStage.id))
      .subscribe(progress => {
        this.currentprogress = progress;
        if (progress != null && progress.progress ===  StageCandidateApplicationProgress.Complete) {
          if ((this.Stages.length - 1) !== this.Stages.indexOf(this.currentStage)) {
            // go To Next Stage
            this.currentStage = this.Stages[this.Stages.indexOf(this.currentStage) + 1];
            this.getStageCandidateJob();
          } else {
             // End of Questions
            this.isComms = true;
            this.comsMessage = this.currentStage.qualifiedComm;

          }
        } else if (progress != null && (progress.progress ===  StageCandidateApplicationProgress.InProgress
          || progress.progress ===  StageCandidateApplicationProgress.Incomplete)) {
          // Continue with this stage
          this.currentStage = this.Stages.filter( stage => this.currentStage.id === stage.id)[0];
          this.getQuestionsForStage(this.currentStage.id);
        }
        if (progress == null) {
          this.upsertStageCandidateJob(StageCandidateApplicationProgress.InProgress); // stage inProgress now
          this.getQuestionsForStage(this.currentStage.id);
        }
      });
  }

  upsertStageCandidateJob(progress: StageCandidateApplicationProgress): void {
    const update = new CandidateStageProgressModel();
    update.candidateId = this.Candidate.id;
    update.applicationId = this.applicationModel.id;
    update.stageId = this.currentStage.id;
    update.progress = progress;
    if(this.isPromoted === undefined){
      this.isPromoted = false;
    }
    update.isPromoted = this.isPromoted;
    this.stagescandidatejobService.UpsertStageCandidateJob(update)
      .subscribe(x => {
      });
  }

  nextQuestion(): void {
    this.showBackButton = true;
    this.renderNextQuestion();
  }

  renderNextQuestion(): void {
    if (this.list !== undefined && (this.list.length - 1) !== this.list.indexOf(this.renderedQuestion)) {
      // Load Next Questions
      this.renderedQuestion = this.list[this.list.indexOf(this.renderedQuestion) + 1];

      this.getAnswerForQuestion();
      this.progressAdvance();
    } else if ((this.Stages.length - 1) !== this.Stages.indexOf(this.currentStage)) {
      // Update stage to complete
      this.upsertStageCandidateJob(StageCandidateApplicationProgress.Complete);
      // Load Next stage
      if(this.isPromoted === true || this.isPromoted === undefined){
        this.isComms = true;
      }
      else {
        this.isComms = true;
        this.comsMessage = this.currentStage.unqualifiedComm;

      }

    }else {

       this.isComms = true;
       this.comsMessage = this.currentStage.qualifiedComm;

    }

  }

  getAnswerForQuestion(): void {
    this.questionsService.GetCandidateAnswer(this.Candidate.id, this.renderedQuestion.id)
      .subscribe(x => {
        if (x) {
         /* if (this.renderedQuestion.questionType === 3) {
            this.renderedQuestion.selectItems = [];
            const array = x.answer.split(',');
            array.forEach(r => {
              const item =  this.renderedQuestion.selectItems.filter(d => d.itemName === r);
              this.renderedQuestion.selectItems.push(item[0]);
            });
          }*/
        //  this.renderedQuestion.answer.answer = x.answer;
        } else {
        /*  this.renderedQuestion.answer = new AnswerModel();
          this.renderedQuestion.answer.answer = '';*/
        }
      }, error => {
      });
  }

  ChildComponentOnNextClicked(): void {
    this.onNextButtonClicked.next(true);
  }

  onBackButton(): void {
    this.renderedQuestion = this.list[this.list.indexOf(this.renderedQuestion) - 1];
    if ( this.list.indexOf(this.renderedQuestion) === 0) {
      this.showBackButton = false;
    }
    this.progressBack();
  }

  onDone(): void {
    this.router.navigate(['applications']);
  }

  resetBar(): void {
    this.updateBar();
  }

  progressAdvance(): void {
    this.updateBar();
  }

  progressBack(): void {
    this.updateBar();
  }

  updateBar(): void {
    this.progressStep = ((this.list.indexOf(this.renderedQuestion) + 1) / (this.list.length)) * 100;
    this.progressStyling = `width: ${this.progressStep }%`;
 }

  skipStage(): void {
    this.resetBar();
    if (this.Stages.length > 0) {
      this.currentStage = this.Stages[this.Stages.indexOf(this.currentStage) + 1];
      this.getStageCandidateJob();
      this.getQuestionsForStage(this.currentStage.id);
    }
  }

  validateOnNextButton(isValide: boolean): void {
    if (isValide) {
      console.log('valid')
      this.nextQuestion();
    } else  {
      console.log('not valid');
    }
  }

  isPromotedToTheNextStage(promoted: boolean): void{

    if (promoted === true){
      this.isPromoted = true;
    }else {
      this.isPromoted = false;
    }
  }

  nextStageComms(clicked: boolean) {


    if(clicked === true){
      if( this.isPromoted === false){

        this.router.navigate(['./applications']);

      }
      if(this.isPromoted === true || this.isPromoted === undefined){
        this.currentStage = this.Stages[this.Stages.indexOf(this.currentStage) + 1];
        this.getStageCandidateJob();
        this.showBackButton = false;
      }
      if (!((this.Stages.length - 1) !== this.Stages.indexOf(this.currentStage))){

        this.upsertStageCandidateJob(StageCandidateApplicationProgress.Complete); // stage inProgress now
        this.router.navigate(['./applications']);


      }

      if (!((this.Stages.length - 1) !== this.Stages.indexOf(this.currentStage))){

        this.upsertStageCandidateJob(StageCandidateApplicationProgress.Complete); // stage inProgress now
        this.router.navigate(['./applications']);

      }
      this.isComms = false;
    
    }

  }




}
