import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ModalService} from '../../_modal';
import {ApplicationModel} from '../../../Core/Models/Application.Model';
import {ApplicationsService} from '../../../Services/ApplicationsService/Applications.Service';
import {DocumentsService} from '../../../Services/DocumentsService/documents.service';
import {Guid} from 'guid-typescript';
import {FilterCriteria} from '../../../Core/Models/FilterCriteria';
import * as moment from 'moment';
import {DomSanitizer, Title, Meta} from '@angular/platform-browser';


@Component({
  selector: 'app-application-details',
  templateUrl: './application-details.component.html',
  styleUrls: ['./application-details.component.scss']
})
export class ApplicationDetailsComponent implements OnInit {

  title = 'Application-Details';
  public applicationName: string;
  public applicationDate: Date;
  public linkForm: FormGroup;
  public currentURL: string;
  public socialLink: string;
  application: ApplicationModel;
  apps : ApplicationModel[];
  currentDate: Date;

  isLoaded = false;
  public filterCriteria: FilterCriteria;

  constructor(private applicationsService: ApplicationsService,
              private documentsService: DocumentsService,
              private router: Router,
              private formBuilder: FormBuilder,
              public modalService: ModalService,
              public dom: DomSanitizer,
              private route: ActivatedRoute,
              private titleService: Title,
              private metaService: Meta
              ) {}

  ngOnInit(): void {
    // this.titleService.setTitle(this.title);
    this.currentDate = new Date();
    this.applicationDate = new Date();
    this.applicationName = 'Graduate Application';
    this.currentURL = window.location.href;
    this.socialLink = '';
    this.filterCriteria = new FilterCriteria();
    this.filterCriteria.ApplicationsKeys = Array<string>();
    this.filterCriteria.ApplicationsKeys = [];
    this.linkForm = this.formBuilder.group({
      sharableLink: this.currentURL
    });
    this.apps = new Array<ApplicationModel>();
    this.application = new ApplicationModel();
    this.getApplication();

  }

  getApplication(): void {
    const applicationId = this.route.snapshot.paramMap.get('applicationId');

    this.applicationsService.GetById(Guid.parse(applicationId)).subscribe(application => {
      this.application = application;

      if (!this.application.bannerImage) {
        this.application.bannerImage = 'assets/images/hazie-banner.jpg';
      }

      this.isLoaded = true;
      this.metaService.updateTag( {name: 'description', content: this.application.shortDescription});
      this.titleService.setTitle("HAZIE - " + this.application.title);
      if (this.application.bannerImage) {
        this.metaService.updateTag({ property: 'og:image', content: this.application.bannerImage})
      } else {
        this.metaService.removeTag("property='og:image'")
      }

    });
  }

  shareToSocials(socialNetwork: string): void {
    if (socialNetwork === 'facebook') {
      this.socialLink = 'https://www.facebook.com/sharer/sharer.php?u=' + this.currentURL;
    } else if (socialNetwork === 'twitter') {
      this.socialLink = 'https://twitter.com/intent/tweet?url=' + this.currentURL;
    } else if (socialNetwork === 'linkedin') {
      this.socialLink = 'https://www.linkedin.com/shareArticle?url=' + this.currentURL;
    } else if (socialNetwork === 'whatsapp') {
      this.whatsappSocialShare();
    } else if ('link') {
      const textArea: HTMLTextAreaElement = document.createElement('textarea') as HTMLTextAreaElement;

      textArea.value = decodeURIComponent(this.currentURL);
      document.body.appendChild(textArea);

      // highlight TextArea to copy the sharing link
      if (true) {
        const range = document.createRange();
        range.selectNodeContents(textArea);
        const selection = document.defaultView.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
        textArea.readOnly = true;
        textArea.setSelectionRange(0, 999999);
      } else {
        textArea.select();
      }
      document.execCommand('copy');
      document.body.removeChild(textArea);

    }
  }

  whatsappSocialShare(): void {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      window.open('whatsapp://send?text=' + this.currentURL);
    } else {
      window.open('https://web.whatsapp.com://send?text=' + this.currentURL);
    }
  }

  goToApplicationQuestions(application: ApplicationModel): void {

    /* Hardcode OldMutual Redirect */
    if (application.companyId === '1f5f7eb5-734f-b345-ca11-5f75b9286328') {
      window.open('https://oldmutual.hazie.co.za', '_blank');
    } else {
      this.router.navigate(['candidate-questions', application.id]);
    }
  }


  applicationClosed(x: ApplicationModel): boolean  {
    const date = new Date(x.expiryDate);
    if (moment(date).isBefore(this.currentDate)) {
      return false;
    } else if (moment(date).isSame(this.currentDate)) {
      return false;
    } else if (moment(new Date(date)).isAfter(this.currentDate)) {
      return  true;
    }
  }

}

