<div class="container background">
  <div class="left-box">
    <div class="company-logo">
      <a routerLink="/login">
        <img  class="logo" src="../../../../assets/logos/Hazie-logo-white.png" alt="Card image cap">
      </a>
    </div>
  </div>

  <div class="right-box">
    <div class="card">
      <div class="card-header">
        <div class="company-logo">
          <a routerLink="/login">
            <img  class="logo" src="../../../../assets/logos/Hazie-logo.png" alt="Card image cap">
          </a>
        </div>

        <div class="header-text">
          <h1>OOPS!</h1>
          <small>Something just broke.</small>
        </div>
      </div>

      <div class="card-body">
        <div class="form-container">
          <form>
            <label> Mmm... Something went wrong. Please contact support at support@hazie.co.za to report the issue.</label>
            <input type="submit" routerLink="/login" value="Take me home">
          </form>
        </div>
      </div>

      <div class="card-footer">
      </div>
    </div>
  </div>
</div>
