<svg *ngFor="let stage of candidateStages; let i = index"   class="circle-position svg-position">
  <g>
    <circle  *ngIf="stage.progress  == 0"  cx="6" cy="10" r="5" stroke="#707070" stroke-width="1" fill="white" />
    <circle  *ngIf="stage.progress  == 1"  cx="6" cy="10" r="5" stroke="#707070" stroke-width="1" fill="#F29500" />
    <circle  *ngIf="stage.progress  == 2 && stage.isPromoted"  cx="6" cy="10" r="5" stroke="#707070" stroke-width="1" fill="#37C150" />
    <circle  *ngIf="stage.progress  == 2 && !stage.isPromoted"  cx="6" cy="10" r="5" stroke="#707070" stroke-width="1" fill="#dc3545" />
    <text x="0" y="30" fill="#8E879D"  font-size="9" font-stretch="normal">{{stage.name}}</text>
    <line *ngIf="i !== candidateStages.length - 1"  x1="10" y1="10" x2="400" y2="10" style="stroke:#707070;stroke-width:1" />
  </g>
</svg>
