import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {FilterCriteria} from '../../Core/Models/FilterCriteria';
import {Guid} from 'guid-typescript';
import {CandidatesPerStageModel} from '../../Core/Models/CandidatesPerStage.Model';
import {Injectable} from '@angular/core';

@Injectable()
export class CandidatesPerStageService {

  baseUrl = environment.baseUrl + environment.apiVersion;
  CandidatesPerStageEndpoints = `${this.baseUrl}/CandidatesPerStage/`;
  constructor(private httpClient: HttpClient) {
  }

  GetById(id: string): Observable<CandidatesPerStageModel> {
    return this.httpClient.get<CandidatesPerStageModel>(`${this.CandidatesPerStageEndpoints}GetById?id=${id}`);
  }

  GetByFilterCriteria(filterCriteria: FilterCriteria): Observable<CandidatesPerStageModel[]> {
    return this.httpClient.post<CandidatesPerStageModel[]>(`${this.CandidatesPerStageEndpoints}all`, filterCriteria);
  }

  AllCount(filterCriteria: FilterCriteria): Observable<number> {
    return this.httpClient.post<number>(`${this.CandidatesPerStageEndpoints}AllCount`, filterCriteria);
  }

  Upsert(model: CandidatesPerStageModel): Observable<string> {
    return this.httpClient.post<string>(`${this.CandidatesPerStageEndpoints}Upsert`, model);
  }
}
