import {Guid} from 'guid-typescript';

export class FilterCriteria {
  JobKeys: string[];
  IdNumberKeys: string[];
  CandidateKeys: Guid[];
  QuestionKeys: string[];
  ProfileKeys: string[];
  StageKeys: string[];
  DocumentKeys: string[];
  ApplicationsKeys: string[];
  CompanyKeys: string[];
  AnswerKeys: string[];
  MailKeys: string[];
  ReferralKeys: string[];
  EmailKey: string;
  candidateIdKeys: string[];
  candidateProfileKeys: string[];
  companyProfileKeys: string[];
  SourceKeys: string[];
}
